import React from "react";
import { createRoot } from "react-dom/client";
import FormAppContainer from "./components/forms/FormAppContainer";

const loginFormRoot = document.getElementById("react-login-form-root");

if (loginFormRoot) {
    const root = createRoot(loginFormRoot);
    root.render(<FormAppContainer />);
} else {
    console.error("Failed to mount login form because a root element wasn't defined.");
}
