import React from "react";
import { Provider } from "react-redux";
import ThemeProvider from "@onnit-js/ui/components/themes/ThemeProvider";
import GlobalStyle from "@onnit-js/ui/components/themes/GlobalStyle";
import theme from "@onnit-js/ui/components/themes/light";
import Store from "../../configureStore";
import LoginForm from "./login/LoginForm";

const FormAppContainer = () => (
    <ThemeProvider theme={theme}>
        <Provider store={Store}>
            <GlobalStyle />
            <LoginForm selector="#react-login-form-root" />
        </Provider>
    </ThemeProvider>
);

export default FormAppContainer;
