import React from "react";
import { connect, ConnectedProps } from "react-redux";
import Box from "@onnit-js/ui/components/box/Box";
import AccountLoginForm from "./AccountLoginForm";
import { setIsLoading } from "../../../slices/appSlice";
import GlobalState from "../../../interfaces/GlobalState";

const AccountLoginFormContainer: React.FC<React.PropsWithChildren<ReduxProps>> = ({ ...rest }) => {
    const { cart } = rest;
    const initialEmail = cart?.customer_email ?? "";
    const onLoginSuccess = () => {
        const params = (new URL(document.URL)).searchParams;
        const redirectLocation = params.get("next");

        setIsLoading(true);
        if (redirectLocation) {
            console.debug(["Redirecting customer after successful authentication.", redirectLocation]);
            window.location.href = redirectLocation;
        } else {
            console.debug("Redirecting to account dashboard customer after successful authentication.");
            window.location.href = "/cart/account.php";
        }
    };
    return (
        <Box {...rest}>
            <AccountLoginForm
                initialEmail={initialEmail}
                onLoginSuccess={onLoginSuccess}
            />
        </Box>
    );
};

const connector = connect(
    (state: GlobalState) => ({
        cart: state.cart,
    }),
    {
        setIsLoading,
    },
);

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(AccountLoginFormContainer);
