import React from "react";
import ReactDOM from "react-dom";
import AccountLoginFormContainer from "../../shared/auth/AccountLoginFormContainer";

interface Props {
    selector: string;
}

const LoginForm = ({ selector }: Props) => {
    const node = document.querySelector(selector);

    if (!node) {
        return null;
    }

    const AccountLoginFormPortal = <AccountLoginFormContainer />;

    return ReactDOM.createPortal(AccountLoginFormPortal, node);
};

export default LoginForm;
